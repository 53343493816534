/* purgecss start ignore */
/*@ Swal notification style */
.swal2-container .swal2-popup.swal2-toast {
  flex-direction: row !important;
  justify-content: space-between !important;
  padding: 10px !important;
}

/*@ Logo style */
.logo-wrapper img {
    height: 70px;
    object-fit: contain;
}

/*@ Tooltip */

.tooltip {
    position: relative;
    display: inline-block;
  }
  
  .tooltip .tooltiptext {
    visibility: hidden;
    width: 140px;
    background-color: #555;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px;
    position: absolute;
    z-index: 1;
    bottom: 150%;
    left: 50%;
    margin-left: -75px;
    opacity: 0;
    transition: opacity 0.3s;
  }
  
  .tooltip .tooltiptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
  }
  
  .tooltip:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
  }

  /* Dropzone css starts*/
  .vue-dropzone > .dz-preview .dz-details {
    opacity: 1 !important;
    position: relative !important;
    background-color: transparent !important;
    color: #000 !important;
  }
  
  #customdropzone .dz-preview {
    width: 20%;
    display: inline-block;
  }
  #customdropzone .dz-preview .dz-image {
    width: 80px;
    height: 80px;
    margin-left: 40px;
    margin-bottom: 10px;
    margin: 0px auto;
  }
  #customdropzone .dz-preview .dz-image > div {
    width: inherit;
    height: inherit;
    border-radius: 50%;
    background-size: contain;
  }
  #customdropzone .dz-preview .dz-image > img {
    width: 100%;
  }
  
  #customdropzone .dz-preview .dz-details {
    color: white;
    transition: opacity 0.2s linear;
    text-align: center;
  }

  #customdropzone .dz-success-mark,
  .dz-error-mark,
  .dz-progress {
    display: none;
  }
  
  #customdropzone .dz-preview .dz-remove {
    position: relative!important;
    opacity: 1!important;
    color: #000!important;
    border: none!important;
    padding: 0px!important;
    margin: 0px!important;
  }
  /* Dropzone css ends*/

  /*@ Start: Checkbox Input */
  .container:hover input ~ .checkmark {
    background-color: #a0aec0;
  }
  
  .container input:checked ~ .checkmark {
    background-color: #0078d4;
  }
  
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  .container input:checked ~ .checkmark:after {
    display: block;
  }
  
  .container .checkmark:after {
    left: 9px;
    top: 6px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  /*@ End: Checkbox Input */

  .max-h-table {
    max-height: 300px;
  }

  .register-checkbox {
    border-color: black;
  }

  /* purgecss end ignore */